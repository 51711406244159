.blog-main-wrapper {

    .create-news-blk {
        padding: 24px;
        background: #fff;
        border-radius: 12px;

        .no-padding-left {
            padding-left: 0 !important;
        }

        .no-padding-right {
            padding-right: 0 !important;
        }

        .padding-left-75 {
            padding-left: 7.5px;

            @media only screen and (max-width: 768px) {
                & {
                    padding-left: 0;
                }
            }
        }

        .padding-right-75 {
            padding-right: 7.5px;

            @media only screen and (max-width: 768px) {
                & {
                    padding-right: 0;
                }
            }
        }

        .main-content-box {
            background-color: var(--adminBG);
            border: 1px solid var(--greyBG);
            border-radius: 8px;
            // padding: 15px;
            box-shadow: 0px 1px 2px 0px var(--shade004);
            margin-bottom: 25px;

            .standard-form {
                max-width: 100%;
            }

            .sub-header-box {
                .sub-head {
                    margin: 0;
                    font-size: 18px;
                    padding-bottom: 20px;
                    text-transform: capitalize;
                }
            }

            .rich-Text-box {
                h4 {
                    padding-bottom: 8px;
                }

                .rdw-editor-main {
                    min-height: 115px;
                }
            }

            .each-grid-box {
                .each-TextField {
                    .file-upload {
                        margin-top: 8px;
                        display: flex;
                        gap: 20px;
                        border: 1px dashed var(--Secondary);
                        min-height: 175px;
                        flex-direction: row-reverse;
                        justify-content: flex-end;

                        &.img-added {
                            .img-upload-wrp {
                                width: calc(100% - 170px);
                            }
                        }

                        .uploaded-img {
                            position: relative;
                            padding: 6px;
                            height: 125px;

                            button.MuiButtonBase-root {
                                position: absolute;
                                right: 5px;
                                top: 5px;
                                // border: 1px solid var(--Primary);
                                padding: 5px;

                                svg {
                                    width: 12px;
                                    height: 12px;
                                }
                            }

                            img {
                                width: 150px !important;
                                height: 113px !important;
                                object-fit: contain;
                                object-position: center;
                            }
                        }
                    }
                }
            }

            .img-upload-wrp {
                .form-upload-sec {
                    height: 100%;

                    .image-upload-label {
                        min-height: 100%;
                        height: 100%;
                        width: 100%;
                        border: none;
                        background: var(--shade25);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                }
            }

            &+.navigation-btn-wrp {
                padding-bottom: 0;
            }

        }

        .btn-wrp {
            button {
                height: auto;
            }
        }
    }

    .news-main-list {
        .panel-tabel-sec {
            [data-field="actions"] {
                justify-content: center;

                .MuiButtonBase-root {
                    svg {
                        width: 20px;
                        height: 20px;
                        color: #212B36;
                    }
                }
            }
        }
    }

}