.enquiry-popup .MuiPaper-root.MuiPaper-elevation{
    width: 100%;
    max-width: 760px;
}
.enquiry-popup .MuiDialogContent-root .back-btn-wrp.f-wrp {
  float: right;
}
.enquiry-popup .MuiDialogContent-root .view-details{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.enquiry-popup .MuiDialogContent-root .view-details .each-details-blk {
  padding-right: 10px;
  box-shadow: 1px 1px 2px 0px #e4e4e4;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 6px;
  border-radius: 4px;
}
.enquiry-popup .MuiDialogContent-root .each-details-blk p {
  margin: 0;
  padding-bottom: 6px;
  font-size: 14px;
}
.back-btn-wrp{
  .MuiButtonBase-root{
    float: right;
  }
}
.delete-popup .back-btn-wrp{
  display: none;
}
.delete-popup .delete-enquiry-box p{
  margin: 0;
  padding-bottom: 15px;
  font-size: 14px;
}
.delete-popup .delete-enquiry-box .btn-wrp{
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}
.delete-popup .delete-enquiry-box .btn-wrp button{
  padding: 10px 30px;
  border-radius: 50px;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 600;
}
.delete-popup .delete-enquiry-box .btn-wrp button.cancel-btn{
  color: #7B7B7B;
}
.delete-popup .delete-enquiry-box .btn-wrp button.cancel-btn:hover{
  background: #f1f1f1;
}
.delete-popup .delete-enquiry-box .btn-wrp button.confirm-btn{
  background: #E62A2A;
  color: #ffffff;
}