.public-DraftStyleDefault-block {
    margin: 0;
}
.editorClassName.rdw-editor-main{
    padding-top: 1em;
    padding-bottom: 1em;
}
.rdw-editor-main [data-text="true"],
.readonly-editor [data-text="true"] {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    color: inherit;
    background-color: inherit;
    text-align: inherit;
    text-decoration: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    word-spacing: inherit;
    text-indent: inherit;
    white-space: inherit;
    text-shadow: inherit;
    line-height: inherit;
}
.rdw-editor-toolbar {
    visibility: visible !important;
    border: 1px solid rgba(145, 158, 171, 0.32);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
}

.rdw-editor-main {
    border: 1px solid rgba(145, 158, 171, 0.32);
    height: 100%;
    padding: 0 10px;
    border-radius: 4px;
    min-height: 200px;
}