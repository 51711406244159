.btn-wrp {
  display: flex;
  align-items: center;
  gap: 20px;

  &.navigation-btn-wrp {
    display: block;
    width: 100%;
    float: left;
    position: relative;
    padding-bottom: 25px;

    .left-btn-box,
    .right-btn-box {
      display: flex;
      gap: 20px;

      .back-btn {
        padding-left: 0;
        min-width: 10px;
        padding-top: 0;
        padding-bottom: 0;
        text-decoration: none;

        svg {
          width: 18px;
          height: 15px;
          transform: rotate(0deg);
        }

        &:hover {
          svg {
            transform: rotate(0deg) scale(1.02);
          }
        }
      }
    }

    .left-btn-box {
      float: left;
    }

    .right-btn-box {
      float: right;
    }
  }
}

.pln-btn {
  border-radius: 8px;
  background: transparent;
  display: flex;
  padding: 10px 12px;
  color: #212B36;
  font-size: 14px;
  width: max-content;
  font-weight: 600;
  font-family: roboto;
  align-items: center;
  transition: 0.5s all;
  transform: scale(1);
  text-transform: unset;

  svg {
    transition: 0.5s all;
    transform: rotate(0deg);
  }

  &:hover {
    transition: 0.5s all;
    transform: scale(1.02);

    svg {
      transition: 0.5s all;
      transform: rotate(45deg);
    }
  }

  &.Mui-disabled {
    color: #2065D1;
    cursor: not-allowed;
  }
}

.sqr-btn {
  border-radius: 8px;
  background: #2065D1;
  display: flex;
  padding: 5px 25px;
  width: max-content;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-family: roboto;
  align-items: center;
  transition: 0.5s all;
  transform: scale(1);
  text-transform: unset;

  &.back-btn {
    background: var(--greyBGE6);
    color: var(--Text);
  }

  &.brdr-btn {
    background: transparent;
    border: 1px solid #2065D1;
    color: #2065D1 !important;

    &.Mui-disabled {
      cursor: not-allowed;
      background-color: transparent;
    }
  }

  svg {
    transition: 0.5s all;
    transform: rotate(0deg);
  }

  &:hover {
    transition: 0.5s all;
    transform: scale(1.02);
    background: #2065D1;

    svg {
      transition: 0.5s all;
      transform: rotate(45deg);
    }
  }

  &.Mui-disabled {
    color: #fff;
    cursor: not-allowed;
    background-color: #2065D1;
  }
}

.table-loader {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PC-Table {
  .app-grid-tool-bar {
    .MuiButtonBase-root.MuiButton-root {
      display: none;
    }

    .MuiFormControl-root.MuiTextField-root {
      input {
        padding: 14.5px 0px;
      }
    }


  }
}