.art-main-wrapper {

    .create-news-blk {
        padding: 24px;
        background: #fff;
        border-radius: 12px;

        .no-padding-left {
            padding-left: 0 !important;
        }

        .no-padding-right {
            padding-right: 0 !important;
        }

        .padding-left-75 {
            padding-left: 7.5px;

            @media only screen and (max-width: 768px) {
                & {
                    padding-left: 0;
                }
            }
        }

        .padding-right-75 {
            padding-right: 7.5px;

            @media only screen and (max-width: 768px) {
                & {
                    padding-right: 0;
                }
            }
        }

        .main-content-box {
            background-color: var(--adminBG);
            border: 1px solid var(--greyBG);
            border-radius: 8px;
            // padding: 15px;
            box-shadow: 0px 1px 2px 0px var(--shade004);
            margin-bottom: 25px;

            .standard-form {
                max-width: 100%;
            }

            .sub-header-box {
                .sub-head {
                    margin: 0;
                    font-size: 18px;
                    padding-bottom: 20px;
                    text-transform: capitalize;
                }
            }

            .rich-Text-box {
                h4 {
                    padding-bottom: 8px;
                }

                .rdw-editor-main {
                    min-height: 115px;
                }
            }

            .each-grid-box {
                .each-TextField {
                    .file-upload {
                        margin-top: 8px;
                        display: flex;
                        gap: 10px;
                        border: 1px dashed var(--Secondary);
                        min-height: 175px;
                        flex-direction: column;
                        // justify-content: flex-end;
                        justify-content: space-around;

                        &.no-img.art-images {
                            .img-upload-wrp {
                                height: 175px;
                            }
                        }

                        &.img-added.art-images {
                            .img-upload-wrp {
                                height: 100%;
                            }
                        }

                        .uploaded-img {
                            position: relative;
                            padding: 6px;
                            height: 125px;

                            button.MuiButtonBase-root {
                                position: absolute;
                                right: 5px;
                                top: 5px;
                                // border: 1px solid var(--Primary);
                                padding: 5px;

                                svg {
                                    width: 12px;
                                    height: 12px;
                                }
                            }

                            img {
                                width: 150px !important;
                                height: 113px !important;
                                object-fit: contain;
                                object-position: center;
                            }
                        }

                        .img-upload-wrp+.art-preview-box {
                            border-top: 1px dashed var(--Secondary);
                        }

                        .art-preview-box {
                            display: flex;
                            gap: 6px;
                            overflow-x: auto;
                            padding: 3px;

                            &::-webkit-scrollbar {
                                width: 100%;
                                height: 5px;

                            }

                            &::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 5px #fff;
                                border-radius: 1px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: var(--Secondary);
                                border-radius: 2px;
                            }

                            &::-webkit-scrollbar-thumb:hover {
                                background: var(--Secondary);
                            }


                            .each-img-blk {
                                span {
                                    button.MuiButtonBase-root {
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                        padding: 5px;
                                        width: 20px;
                                        height: 20px;

                                        svg {
                                            width: 15px;
                                            height: 15px;
                                        }
                                    }

                                    .rc {
                                        display: block;
                                        width: 90px;
                                        height: 90px;
                                    }
                                }
                            }
                        }
                    }
                }

                .author-image {
                    .file-upload {
                        margin-top: 1.5em;
                        min-height: auto;
                        justify-content: center;

                        &.no-img {
                            padding: 9px;
                        }

                        .uploaded-img {
                            height: auto;

                            .rc {
                                width: 85px;
                            }

                            img {
                                width: 85px !important;
                                height: 85px !important;
                            }
                        }
                    }
                }
            }

            .img-upload-wrp {
                .form-upload-sec {
                    height: 100%;

                    .image-upload-label {
                        min-height: 100%;
                        height: 100%;
                        width: 100%;
                        border: none;
                        background: var(--shade25);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        padding-top: 15px;
                    }
                }
            }

            .tag-category-box {
                .flex-box {
                    display: flex;
                    gap: 15px;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 15px;
                    padding: 10px;
                    border: 1px dashed var(--Secondary);
                    margin-top: 15px;

                    li {
                        background: #2065D1;
                        color: #fff;
                        padding: 8px;
                        border-radius: 5px;
                        position: relative;

                        .MuiIconButton-root {
                            position: absolute;
                            right: -8px;
                            top: -8px;
                            padding: 5px;
                            background: #212B36;

                            svg {
                                width: 10px;
                                height: 10px;

                                path {
                                    stroke: #fff;
                                }
                            }
                        }
                    }
                }
            }


            &+.navigation-btn-wrp {
                padding-bottom: 0;
            }

        }

        .btn-wrp {
            button {
                height: auto;
            }
        }
    }

    .art-main-list {
        .panel-tabel-sec {
            [data-field="actions"] {
                justify-content: center;

                .MuiButtonBase-root {
                    svg {
                        width: 20px;
                        height: 20px;
                        color: #212B36;
                    }
                }
            }
        }
    }

}

.category-popup {
    .back-btn-wrp {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .MuiPaper-root.MuiPaper-elevation {
        max-width: 895px;
    }

}

.category-list-wrp {
    .category-add-wrp {
        display: flex;
        gap: 15px;
        align-items: flex-end;

        .category-icon {
            flex-shrink: 0;

            .icon-box {
                button.selectIconButton {
                    border: 1px dashed #2065D1;
                    border-radius: 5px;
                    background-color: #fff;
                    height: 55px;
                    width: 80px;
                    cursor: pointer;

                    p {
                        margin: 0;
                    }
                }

                &.form-upload-view {
                    border: 1px dashed #2065D1;
                    border-radius: 5px;
                    background-color: #fff;
                    height: 55px;
                    width: 80px;
                    position: relative;

                    .delete-btn {
                        background-color: transparent;
                        width: 13px;
                        height: 13px;
                        padding: 0;
                        position: absolute;
                        right: 2px;
                        top: 2px;
                        border: none;

                        svg {
                            width: 100%;
                            height: 100%;
                        }

                        &+span {
                            display: block;
                            width: 100%;
                            height: 100%;
                            padding: 7px;

                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }

        }

        .btn-wrp {
            .MuiButton-root {
                height: 55px;
            }
        }
    }

    .category-list {

        .category-preview {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            padding-top: 15px;
            margin-top: 15px;
            border-top: 1px solid #e4e4e4;

            .each-img-box {
                display: flex;
                position: relative;
                align-items: center;
                border-radius: 8px;
                display: flex;
                color: #fff;
                padding: 8px;
                border-radius: 5px;
                white-space: pre-line;
                width: -webkit-max-content;
                width: max-content;
                background: #2065D1;
                gap: 4px;

                span {
                    line-height: 1;

                    svg {
                        path {
                            --iconColor: #fff;
                        }
                    }
                }

                .delete-btn {
                    width: 18px;
                    height: 18px;
                    border: none;
                    position: absolute;
                    right: -4px;
                    top: -4px;
                    padding: 0px;
                    background: #212B36;
                    border-radius: 100%;

                    svg {
                        width: 9px;
                        height: 9px;

                        path {
                            stroke: #fff;
                        }
                    }
                }

            }
        }
    }
}