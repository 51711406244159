.pagination{
    display: flex;
    align-items: center;
    padding: 0px 20px;
    p{
        margin: 0;
        padding-right: 10px;
    }
    select{
        background: transparent;
        border: none;
    }
    .pagination-text{
        padding-right: 10px;
        padding-left: 10px;
    }
    .pagination-button{
        padding: 0;
        margin: 0;
        line-height: 0;
        min-width: 4px;
        border: none;
        background: transparent;
        width: 40px;
        svg{
            width: 14px;
            height: 14px;
            cursor: pointer;
        }
        b{
            color: var(--White);
            background: var(--Secondary);
            display: inline-block;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            border-radius: 100%;
            font-size: 12px;
            cursor: pointer;
        }
    }
    .pagination-text-box{
        width: 30px;
        height: 30px;
        border-radius: 100%;
        color: var(--White);
        background: var(--Primary);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}