
.image-selection-popup{
    .MuiPaper-root.MuiPaper-elevation{
        max-width: 895px;
        .image-list{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            padding-top: 2px;
            li{
                button{
                    width: 110px;
                    height: 110px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0px 0px 0px 1px #e4e4e4;
                    background: transparent;
                    padding: 2px;
                    transition: 0.3s all;
                    cursor: pointer;
                    border: none;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }
                &.active,
                &:hover{
                    button{
                        transition: 0.3s all;
                        box-shadow: 0px 0px 0px 2px #8032A1;
                    }
                }

            }

            &.icon-list{
                li{
                    button{
                        width: 45px;
                        height: 45px;
                        svg{
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }
        }
        .MuiDialogActions-root{
            .standard-btn{
                background-color: #103996;
            }
        }
    }
}

:root{
    --iconColor: #000;
    --svgBG: #fff;
}