html,
body {
    /* background-color: var(--White); */
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto !important;
    /* font-family: "Roboto", sans-serif; */
}

@media only screen and (max-width: 991px) {

    html,
    body {
        padding-right: 0 !important;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.f-wrp {
    width: 100%;
    display: block;
    position: relative;
    float: left;
}

img {
    width: 100%;
    display: block;
}

ul,
li {
    list-style: none;
    padding-left: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
}



@media only screen and (max-width: 800px) {}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 650px) {}

@media only screen and (max-width: 500px) {}

@media only screen and (max-width: 450px) {}

@media only screen and (min-width: 600px) {
    .custom-header {
        min-height: 45px;
    }
}